import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "page__header" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Tabs = _resolveComponent("Tabs")!
  const _component_UiInput = _resolveComponent("UiInput")!
  const _component_PageControls = _resolveComponent("PageControls")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Tabs, {
      tabs: _ctx.tabs,
      activeTabIndex: _ctx.currentTab,
      onTabClick: _ctx.tabClick
    }, null, 8, ["tabs", "activeTabIndex", "onTabClick"]),
    _createVNode(_component_PageControls, null, {
      default: _withCtx(() => [
        _createVNode(_component_UiInput, {
          modelValue: _ctx.filters$.tno,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_ctx.$store.commit('registry/SET_REGISTRY_SEARCH', { value: $event, entity: 'taxPayersSearch' }))),
          search: "",
          label: "УГНС",
          placeholder: "Введите"
        }, null, 8, ["modelValue"])
      ]),
      _: 1
    })
  ]))
}