import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "tabs" }
const _hoisted_2 = { class: "tab" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Btn = _resolveComponent("Btn")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tabs, (tab, index) => {
        return (_openBlock(), _createBlock(_component_Btn, {
          class: "tab__btn",
          type: _ctx.activeTabIndex === index ? 'primary' : '',
          onClick: ($event: any) => (_ctx.tabClick(index))
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(tab.name), 1)
          ]),
          _: 2
        }, 1032, ["type", "onClick"]))
      }), 256))
    ])
  ]))
}