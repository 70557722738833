
import { defineComponent, ref } from 'vue';
import { useRouter } from 'vue-router';
import { ObjectStatus } from '@/constants/objectStatuses';
import ColorSelector from '@/components/UI/ColorSelector.vue';
import PageControls from '@/components/PageControls.vue';
import Tabs from '@/components/UI/Tabs/index.vue';
import ContainerHeader from '@/components/ContainerHeader.vue';
import searchIcon from '@/assets/images/ic-search.svg';
import api from '@/api';
import store from '@/store';

export default defineComponent({
  name: 'TaxHeader',
  components: {
    ColorSelector,
    PageControls,
    Tabs,
    ContainerHeader,
  },
  props: ['filters'],
  emits: ['update:filters'],
  setup(props) {
    const router = useRouter();
    const currentTab = ref(1);
    const selectedStatus = ref<ObjectStatus>({ name: 'Не выбран', color: 'transparent' });
    const tabs = [
      {
        name: 'Объекты торговли',
        onClick() { router.push('/registry/trade-objects'); },
      },
      {
        name: 'Налоговые органы',
        onClick() { router.push('/registry/ugns'); },
      },
      {
        name: 'Налогоплательщики',
        onClick() { router.push('/registry/nalogpayers'); },
      },
    ];
    const tabClick = (e: number) => {
      currentTab.value = e;
    };

    async function onFileUpload(files: File[]) {
      try {
        await api.user.loadfile(files[0]);
        store.dispatch('taxPayers/GET_PAYERS_LIST');
        alert('Пользователи успешно добавлены');
      } catch (e) {
        alert('Ошибка при добавлении пользователей');
      }
      // TODO: toast и правильный ли роут
    }

    function onClickDownloadTpl() {
      api.user.downloadTemplate();
    }

    return {
      filters$: props.filters, // hack for two-way binding objects
      tabs,
      currentTab,
      tabClick,
      searchIcon,
      selectedStatus,
      onFileUpload,
      onClickDownloadTpl,
    };
  },
});
