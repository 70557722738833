import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TaxHeader = _resolveComponent("TaxHeader")!
  const _component_UiTable = _resolveComponent("UiTable")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_TaxHeader, {
      filters: _ctx.filters,
      "onUpdate:filters": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.filters) = $event))
    }, null, 8, ["filters"]),
    _createVNode(_component_UiTable, {
      rows: _ctx.ugnsFiltered,
      cols: _ctx.cols,
      emptyBannerText: _ctx.ugns.fetching ? 'Идёт загрузка...' : 'Нет данных'
    }, null, 8, ["rows", "cols", "emptyBannerText"])
  ]))
}