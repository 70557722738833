
import {
  computed, defineComponent, reactive,
} from 'vue';
import TaxHeader from '@/components/views/tax/TaxHeader.vue';
import api from '@/api';
import { TnoRow } from '@/types/Statistic';
import { useStore } from 'vuex';

export default defineComponent({
  name: 'UgnsIndex',
  components: { TaxHeader },
  setup() {
    const store = useStore();
    const filters = reactive({
      tno: computed(() => store.getters['registry/GET_REGISTRY_SEARCH']('taxPayersSearch')),
    });
    const ugns = reactive({
      fetching: false,
      rows: [] as TnoRow[],
      async fetch() {
        this.fetching = true;
        try {
          this.rows = (await api.statistic.getForAllTno()).data;
        } catch (e) {
          console.log(e);
        } finally {
          this.fetching = false;
        }
      },
    });
    ugns.fetch();
    const ugnsFiltered = computed(() => {
      const searchTno = filters.tno;
      const res: TnoRow[] = ugns.rows.filter((row: TnoRow) => {
        const resTno = !searchTno || (`${row.tnoName.toLowerCase()}`.includes(searchTno.replace(/ /g, '').toLowerCase()));
        return resTno;
      });
      return res;
    });

    const cols = [
      {
        label: 'УГНС',
        field: 'tnoName',
        component: (row: TnoRow) => ({
          name: 'router-link',
          value: row.tnoName || null,
          class: 'link',
          to: { name: 'registry-ugns-id', params: { ugnsId: row.id } },
        }),
      },
      {
        label: 'Телефон',
        field: 'phoneNumber',
      },
      {
        label: 'Торговые объекты',
        field: 'tradeObjects',
      },
      {
        label: 'Налогоплательщики',
        field: 'taxPayersCount',
      },
      {
        label: 'Обработано',
        field: 'updateObjects',
      },
      {
        label: 'не Обработано',
        field: 'notUpdateObjects',
      },
    ];

    return {
      ugns, ugnsFiltered, cols, filters,
    };
  },
});
