
import { defineComponent, PropType, ref } from 'vue';
import { TabModel } from '@/components/UI/Tabs/models';

export default defineComponent({
  name: 'Tabs',
  props: {
    tabs: { required: true, type: Array as PropType<TabModel[]> },
    activeTabIndex: { required: true, type: Number },
  },
  setup(props, { emit }) {
    const activeTab = ref<number>(props.activeTabIndex || 0);

    const tabClick = (index: number) => {
      props.tabs[index].onClick();
      emit('tabClick', index);
    };

    return { activeTab, tabClick };
  },
});

